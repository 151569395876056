<template>
  <v-row class="home">
    <!-- alert -->
    <v-col cols="12" class="pa-0" v-if="Object.keys(alertCertificate).length && alertCertificate.show_notification">
      <v-alert class="mb-3 inf-alert-error" text outlined color="error" rounded dense>
        <div class="d-flex pa-0 align-center">
          <v-icon color="error">mdi-alert</v-icon>
          <div class="ml-5 alert-description-dash">
            <span class="body-1 font-weight-bold error--text">Certificado digital</span>
            <span class="d-block body-2 error--text pb-1">
              <template v-if="alertCertificate.status === 'active'">
                Te queda{{alertCertificate.days_left > 1 ? 'n' : ''}} <span class="font-weight-bold">{{alertCertificate.days_left }} {{ alertCertificate.days_left > 1 ? 'días' : 'día'}}</span> restantes, con fecha de vencimiento:
              </template>
              <template v-else>Tu certificado digital venció el</template>
              <span class="font-weight-bold"> {{ alertCertificate.date_end | dateBar }}</span>
            </span>
          </div>
          <v-spacer />
          <v-btn v-if="$helpers.hasPermission(['add_genericcertificate'])" :to="{ name: 'IrsBuiltInSettings' }" color="error">Gestionar certificado</v-btn>
        </div>
      </v-alert>
    </v-col>
    <!-- end alert -->
    <v-col cols="12" class="pa-0 mt-2">
      <v-card class="panel-home" width="100%" height="200" :style="`margin-bottom:${setMarginBottom}`">
        <v-img min-width="100%" :min-height="200" :src="require(`@/assets/backgrounds/background-panel-hello${$vuetify.theme.dark ? '--dark' : ''}.svg`)">
          <v-row class="fill-height ma-0" align="center">
            <span class="d-block pl-12 title-panel primary--text">¡Hola{{ $store.state.auth.userAccount.first_name ? ` ${$store.state.auth.userAccount.first_name}`: ''}}!
              <span class="d-inline-block text-h1 ml-3" style="margin-top: -20px; position: absolute">👋🏼</span>
            </span>
          </v-row>
        </v-img>
      </v-card>
      <v-col class="px-0 text-end">
        <v-row align="center" class="mt-n8 mb-n2" no-gutters>
          <span class="font-weight-bold subtitle-2 defaultGrey--text">Tus accesos directos</span>
          <v-spacer />
          <v-btn @click="dialog=true" text color="primary" :ripple="false">
            <span class="font-weight-medium body-1">Editar accesos directos</span>
            <v-icon small class="ml-2">mdi-open-in-new</v-icon>
          </v-btn>
        </v-row>
        <v-row v-for="(shortcut, i) in currentShortcutsList" :key="i">
          <v-col cols="3" class="" v-for="children in shortcut.children" :key="children.title">
            <!-- <v-hover v-slot="{ hover }"> -->
              <v-card class="px-5 py-7" :to="{ name: children.route }" height="80" min-width="190"> <!--:elevation="hover ? 6 : 0"-->
                <v-row class="fill-height ma-0 text-left" align="center">
                  <v-img contain :height="44" style="position: absolute" :src="require(`@/assets/shortcuts-icons/avatars/${children.icon}--avatar.svg`)" />
                  <span class="d-inline-block defaultGrey--text font-weight-bold ml-14 pl-2" :class="$vuetify.breakpoint.width <= 1200 ? 'body-1' : 'subtitle-1'" style="line-height: normal" >{{ children.title }}</span>
                </v-row>
              </v-card>
            <!-- </v-hover> -->
          </v-col>
        </v-row>
      </v-col>
    </v-col>
    <!-- dialog -->
    <v-dialog v-model="dialog" width="1185" persistent scrollable no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Accesos directos</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="clear" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0 pb-6">
          <!-- alert -->
          <v-alert class="inf-alert primary--text" :height="50" dense text outlined tile>
            <div class="d-flex pa-0">
              <v-icon color="primary" size="22">mdi-information</v-icon>
              <div class="ml-4 py-1">
                <span class="body-1">Selecciona hasta
                  <span class="font-weight-bold">8 accesos directos</span>
                </span>
              </div>
            </div>
          </v-alert>
          <!-- end alert -->
          <v-col class="px-5 pb-1" v-for="(section) in shortcuts" :key="section.title">
            <span class="font-weight-bold subtitle-2 defaultGrey--text">{{ section.title }}</span>
            <v-row class="mt-4" no-gutters>
              <v-col cols="2" :class="{'pr-3' : i !== section.children.length - 1 }" v-for="(children, i) in section.children" :key="children.title" style="min-width: 16.6% !important;">
                <v-card class="pa-3" outlined width="235" style="border-radius: 5px" @click.stop="children.show = !children.show, getShortcuts(children)" :ripple="false">
                  <v-row class="ma-0 mt-n1" align="center" no-gutters>
                    <v-sheet height="38">
                      <v-row class="ma-0" align="center">
                        <v-img contain :height="44" style="position: absolute" :src="require(`@/assets/shortcuts-icons/${ section.title === 'Configuración' ? children.icon : section.icon}--icon.svg`)" />
                        <v-sheet class="ml-7 body-2 align-center d-flex defaultGrey--text" width="90" height="40" style="line-height: normal">{{ children.title }}</v-sheet>
                        <v-checkbox class="mt-n1" v-model="children.show" @click.stop="getShortcuts(children)" :ripple="false" dense hide-details style="position: absolute; right: 0"/>
                      </v-row>
                    </v-sheet>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="clear" :ripple="false">Cancelar</v-btn>
          <v-btn color="primary" @click="saveShortCuts" :ripple="false">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog -->
  </v-row>
</template>
<script>
import GenericViewMixin from '@/mixins/GenericViewMixin'
export default {
  mixins: [
  GenericViewMixin
  ],
  data: () => ({
    loadingAlertCertificate: false,
    alertCertificate: {},
    dialog: false,
    min: 0,
    max: 0,
    list: ['Documentos emitidos', 'Crear documentos', 'Proveedores', 'Disponibilidad', 'POS', 'Notificaciones', 'Clientes', 'Envío de documentos'],
    currentShortcutsList: [],
    shortcuts: [
      {
        icon: 'documents',
        title: 'Emisión',
        description: '',
        children: [
          {
            icon: 'documents',
            title: 'Documentos emitidos',
            route: 'DocumentsList',
            show: false
          },
          {
            icon: 'documents',
            title: 'Crear documentos',
            route: 'DocumentsCreate',
            show: false
          },
          {
            icon: 'documents',
            title: 'Emisión por lotes',
            route: 'DocumentsBatchListCreate',
            show: false
          },
          {
            icon: 'documents',
            title: 'Clientes',
            route: 'CustomerListCreate',
            show: false
          }
          // {
          //   icon: 'documents',
          //   title: 'Productos',
          //   route: '',
          // show: false,
          // }
        ]
      },
      {
        icon: 'purchases',
        title: 'Recepción',
        description: '',
        children: [
          {
            icon: 'purchases',
            title: 'Documentos recibidos',
            route: 'PurchaseList',
            show: false
          },
          // {
          //   icon: 'purchases',
          //   title: 'Cesión',
          //   route: '',
          // show: false
          // },
          // {
          //   icon: 'purchases',
          //   title: 'Automati-zación',
          //   route: '',
          // show: false
          // },
          {
            icon: 'purchases',
            title: 'Proveedores',
            route: 'SupplierListCreate',
            show: false
          },
          {
            icon: 'purchases',
            title: 'Segmentos',
            route: 'SuppliersGroupsList',
            show: false
          }
        ]
      },
      {
        icon: 'folios',
        title: 'Folios',
        description: '',
        children: [
          {
            icon: 'folios',
            title: 'Disponibilidad',
            route: 'FoliosListCreate',
            show: false
          }
        ]
      },
      {
        icon: 'pos',
        title: 'POS',
        description: '',
        children: [
          {
            icon: 'pos',
            title: 'Tiendas',
            route: 'BranchListCreate',
            show: false
          },
          {
            icon: 'pos',
            title: 'POS',
            route: 'PosListCreate',
            show: false
          }
        ]
      },
      {
        icon: 'reports',
        title: 'Reportes',
        description: '',
        children: [
          {
            icon: 'reports',
            title: 'Envío de documentos',
            route: 'SentDocumentList',
            show: false
          }
        ]
      },
      {
        icon: 'account',
        title: 'Configuración',
        description: '',
        children: [
          {
            icon: 'account',
            title: 'Organización',
            route: 'AccountBasic',
            show: false
          },
          {
            icon: 'sii',
            title: 'SII',
            route: 'IrsBuiltInSettings',
            show: false
          },
          {
            icon: 'directory',
            title: 'Directorio usuarios',
            route: 'AccountMembers',
            show: false
          },
          {
            icon: 'directory',
            title: 'Directorio roles',
            route: 'AccountGroups',
            show: false
          },
          {
            icon: 'developers',
            title: 'Developers',
            route: 'AccountSettingsWebhooksListCreate',
            show: false
          },
          {
            icon: 'notifications',
            title: 'Notificaciones',
            route: 'AccountNotifications',
            show: false
          }
        ]
      }
    ],
    backupList: []
  }),
  computed: {
    setMarginBottom () {
      if (this.$vuetify.breakpoint.width < 1400) return '4%'
      else if (this.$vuetify.breakpoint.width < 1500) return '5%'
      else if (this.$vuetify.breakpoint.width < 1700) return '6.5%'
      else if (this.$vuetify.breakpoint.width < 1900) return '7%'
      return '9.5%'
    }
  },
  mounted () {
    this.shortcuts.forEach((item) => {
      item.children.forEach((children) => {
        children.show = this.list.includes(children.title)
      })
    })
    this.setShortCuts()
    this.loadingAlertCertificate = true
    this.$store.dispatch('accounts/LIST', {
      resource: 'irs/certificates/expiration_alert'
    })
    .then((response) => {
      this.alertCertificate = response.data
    })
    .catch(() => {
      this.alertCertificate = {}
    })
    .finally(() => {
      this.loadingAlertCertificate = false
    })
  },
  methods: {
    getShortcuts (children) {
      if (this.list.length <= 4) {
        if (this.list.includes(children.title)) {
          this.min += 1
          if (this.min === 1) {
            this.$dialog.message.error('Debes tener al menos 4 accesos directos para acceder rápidamente a tus funciones favoritas.')
            setTimeout(() => {
              this.min = 0
            }, 3000)
          }
          this.$nextTick(() => {
            children.show = true
          })
        } else {
          this.list.push(children.title)
          this.$nextTick(() => {
            children.show = true
          })
        }
      } else if (this.list.length >= 8) {
        if (this.list.includes(children.title)) {
          this.list.splice(this.list?.indexOf(children.title), 1)
        } else {
          this.max += 1
          if (this.max === 1) {
            this.$dialog.message.error('Debes tener hasta 8 accesos directos para acceder rápidamente a tus funciones favoritas.')
            setTimeout(() => {
              this.max = 0
            }, 3000)
          }
          this.$nextTick(() => {
            children.show = false
          })
        }
      } else {
        if (!this.list.includes(children.title)) {
          this.list.push(children.title)
        } else {
          this.list.splice(this.list?.indexOf(children.title), 1)
        }
      }
    },
    /* eslint-disable */
    saveShortCuts () {
      let recovery = JSON.parse(localStorage.getItem('shortcuts-fc')) ?? []
      const index = recovery?.findIndex(({account_id}) => account_id === this.$store.state.auth.account.id)
      if (index !== -1) {
        recovery[index].shortcuts = this.list
      } else {
        recovery.push({ account_id: this.$store.state.auth.account.id, shortcuts: this.list })
      }

      localStorage.setItem('shortcuts-fc', JSON.stringify(recovery))
      this.setShortCuts()
      this.dialog = false
    },
    setShortCuts () {
      const recovery = JSON.parse(localStorage.getItem('shortcuts-fc'))
      const found = recovery?.find(({account_id}) => account_id === this.$store.state.auth.account.id)

      if (found || this.list?.length) {
        if (found?.shortcuts) this.list = found?.shortcuts
         else {
          this.list = this.list
        }

        let array = []
        this.shortcuts?.forEach((item) => {
          item.children.forEach((children) => {
            if (this.list.includes(children.title)) {
              array.push(children)
              this.currentShortcutsList = [{ ...item, children: array}]
            }
          })
        })
      }

      this.backupList = [...this.list]
    },
    clear () {
      this.list = [...this.backupList]
      this.shortcuts.forEach((item) => {
        item.children.forEach((children) => {
          children.show = this.list.includes(children.title)
        })
      })
      this.dialog = false
    }
  }
}
</script>